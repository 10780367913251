<template>
    <div>
        <v-toolbar elevation="1" dark color="primary">
                <v-toolbar-title>
                    Consulta SIS <v-spacer/><div v-if="fechaDe">{{fechaDe}} A {{fechaA}}</div>
                </v-toolbar-title>
        </v-toolbar>
        <v-sheet>
            <v-row no-gutters> 
                <v-col xs="12" sm="12" md="3" lg="3" xl="3" cols="12">
                    <v-sheet elevation="1" class="mt-1">
                        <v-date-picker
                            class="mb-2"
                            v-model="fechas"
                            range
                            :max="estaFecha"
                            full-width
                            no-title
                            locale="es-mx"
                            scrollable
                        ></v-date-picker>
                    </v-sheet>
                    <v-autocomplete
                        v-model="medico"
                        :items="listaMedicos"
                        :loading="loadingMedicos"
                        label="Médico"
                        :search-input.sync="queryMedico"
                        item-text="nombre"
                        item-value="id"
                        no-filter
                        rounded
                        outlined
                        dense
                        clearable
                    >
                        <!-- El template para la lista de los datos -->
                        <template v-slot:item="data">
                            <v-list-item-content>
                                <v-list-item-title>
                                    
                                    <v-icon
                                        v-if="data.item.activo!=1"
                                        color="warning"
                                        small
                                    >
                                        mdi-alert
                                    </v-icon>
                                    {{data.item.nombre}}
                                </v-list-item-title>
                                
                                <v-list-item-subtitle>
                                    {{data.item.especialidad}}
                                </v-list-item-subtitle>

                            </v-list-item-content>
                        </template>
                    </v-autocomplete>

                    <v-select
                        v-model="especialidad"
                        :items="especialidades"
                        label="Especialidad"
                        item-text="especialidad"
                        item-value="id"
                        rounded
                        outlined
                        dense
                        clearable
                        @focus="cargaEspecialidades"
                    />

                    <v-btn
                        
                        :disabled="!fechaDe"
                        :loading="loadingAtenciones"
                        @click="cargaAtencionesBtn"
                    >
                        Buscar
                    </v-btn>
                    
                </v-col>
                <v-col class="px-1" xs="12" sm="12" md="9" lg="9" xl="9" cols="12">
                        <v-data-table
                        elevation="2"
                        :headers="headersAtn"
                        :items="atenciones"
                        disable-pagination
                        disable-filtering
                        disable-sort
                        hide-default-footer
                        :loading="loadingAtenciones"
                    >
                        <!--  Datos del paciente  -->
                        <template v-slot:[`item.medicoNombre`]="{ item }">
                            <div class="d-flex flex-row">
                                <div>
                                    <v-icon left v-if="item.programa == 99" color="warning"> mdi-help</v-icon>
                                    <v-icon left v-if="item.programa ==  6"> mdi-tooth</v-icon>
                                    <v-icon left v-if="item.programa ==  7"> mdi-head-question</v-icon>
                                </div>    

                                <div>
                                    {{item.medicoNombre}}<br/>
                                    <span class="text-caption">{{item.especialidadNombre}}</span>
                                </div>    

                            </div>
                        </template>

                        <template v-slot:[`item.can`]="{ item }">

                            <v-chip
                                v-if="item.can > 0"
                                class="ma-2"
                                color="red"
                                text-color="white"
                            >
                                {{item.can}}
                            </v-chip>
                            <span v-if="item.can == 0">{{item.can}}</span>
                        </template>

                        <!--  Datos del paciente  -->
                        <template v-slot:[`item.view`]="{ item }">
                            <v-btn icon  @click="imprimeSolo(item)" v-if="item.especialidad && item.programa != 99">
                                <v-icon >mdi-magnify</v-icon>
                            </v-btn>
                        </template>

                        <template v-slot:[`item.print`]="{ item }">
                            <v-btn icon @click="imprimeSolo(item)" :loading="loadingDescarga" v-if="item.programa != 99" >
                                <v-icon >mdi-text</v-icon>
                            </v-btn>
                        </template>

                    </v-data-table>
                </v-col>
            </v-row>
        </v-sheet>

        <v-dialog
            v-model="dialogPrograma"
            width="300"
        >
            <v-card>
                <v-form v-model="programaForm">
                    <v-card-title>
                        Selecciones el programa a descargar
                    </v-card-title>
                    <v-card-text>
                        <v-radio-group v-model="paramsImprime.programa" :rules="[v => !!v || 'Seleccione un programa']">
                            <v-radio label="Consulta" :value="3"/>
                            <v-radio label="Salud MENTAL" :value="7"/>
                            <v-radio label="Salud BUCAL" :value="6"/>
                        </v-radio-group>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer/>
                        <v-btn color="green" dark :disabled="!programaForm" @click="imprime">
                            Descargar
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    data: () => ({

        loadingDescarga:false,

        fechas:[],
        fechaArr:[],

        paramsImprime:{},

        medico:null,
        listaMedicos:[],
        loadingMedicos:false,
        queryMedico:'',

        especialidad:null,
        especialidades:[],

        paramsConsultas:{},
        loadingAtenciones:false,
        atenciones:[],
        headersAtn:[
            {text:'Medico', value:'medicoNombre', width:'50%'},
            {text:'P.V.', value:'pv', align:'center', width:'5%'},
            {text:'Sub.', value:'su', align:'center', width:'5%'},
            {text:'Esp.', value:'es', align:'center', width:'5%'},
            {text:'Cancel', value:'can', align:'center', width:'5%'},
            {text:'Total', value:'total', align:'center', width:'5%'},
            {text:'', value:'view'},
            {text:'', value:'print'},
        ],

        paramsAtn:{}, //Esto se va a store

        dialogPrograma:false,
        programaForm:false,

    }),
    methods:{

        agregaTotales(){

            let datos ={pv:0, su:0, es:0, total:0}

            //let dt = this.atenciones.map(function(obj){
            //    return obj;
            //});
            datos.pv = this.atenciones.map(itm => (itm.pv)).reduce((a, b) => a + b, 0)
            datos.su = this.atenciones.map(itm => (itm.su)).reduce((a, b) => a + b, 0)
            datos.es = this.atenciones.map(itm => (itm.es)).reduce((a, b) => a + b, 0)
            datos.can = this.atenciones.map(itm => (itm.can)).reduce((a, b) => a + b, 0)
            

            datos.total = datos.pv + datos.su + datos.es
            //return(datos)            
            this.atenciones.push({
                medicoNombre: 'TOTALES',
                pv:datos.pv,
                su:datos.su,
                es:datos.es,
                can:datos.can,
                total:datos.total,
            })
            
        },
        async cargaAtenciones(){
            this.atenciones=[]
            this.loadingAtenciones = true
            try{
                let atn = await this.$http({
                    //url:'/reportesConsulta/reporteCeV2',
                    url:'/reportesConsulta/reporteCeV3',
                    methods:'GET',
                    params:this.paramsAtn
                })
                this.loadingAtenciones = false
                if (atn.data.estado){
                    this.atenciones = atn.data.root
                    this.agregaTotales()
                } else {
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede cargar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
            }catch(err){
                this.loadingAtenciones = false
                this.$store.dispatch('security/revisaError',err)
            }

        },
        cargaAtencionesBtn(){
            this.paramsAtn = {
                medico: this.medico,
                especialidad:this.especialidad,
                fechaDe: this.fechaDe,
                fechaA: this.fechaA,
            }
            this.cargaAtenciones()
        },
        async cargaEspecialidades(){
            try{
                let me = await this.$http({
                    url:'/consulta/especialidadesList',
                    method:'GET',
                })
                this.especialidades = me.data
                this.especialidades.sort((a, b) => a.especialidad.localeCompare(b.especialidad));
            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
            
        },
        async cargaMedico(){
            this.loadingMedicos = true
            try{
                let me = await this.$http({
                    url:'/consulta/medicoCe',
                    method:'GET',
                    params:{query:this.queryMedico}
                })
                this.loadingMedicos = false
                this.listaMedicos = me.data
                this.listaMedicos.sort((a, b) => a.nombre.localeCompare(b.nombre));
            }catch(err){
                this.loadingMedicos = false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        getFecha(fc){
            if (fc){
                return this.moment(fc).format('DD/MM/YYYY')
            } else {
                return('')
            }
        },

        imprimeSolo(itm){

            this.paramsImprime.fechaDe=this.fechaDe
            this.paramsImprime.fechaA=this.fechaA

            if (itm.medico){

                this.paramsImprime.programa = null
                this.paramsImprime.medico = itm.medico
                
                this.imprime()
            } else {
                this.paramsImprime.medico=null
                this.dialogPrograma = true
                
            }

        },

        //selectImprime(){
        //    
        //    this.paramsImprime.programa = 7
        //    this.imprime()
        //},

        

        async imprime(){
            this.dialogPrograma=false
            /*
            try{
                this.loadingDescarga= true
                let dt = await this.$http({
                    url: '/reportesConsulta/reporteSis2',
                    method: 'GET',
                    params:this.paramsImprime,
                    //responseType: 'arraybuffer',
                })

                this.loadingDescarga = false

                // Obtiene el nombre del archivo origen

                console.log(dt.data)
                
                
                
            }catch(err){

                this.loadingDescarga = false
                this.$store.dispatch('security/revisaError',err)
            }
            */
            
            try{
                this.loadingDescarga= true
                let dt = await this.$http({
                    url: '/reportesConsulta/reporteSis2',
                    timeout: 600000,
                    method: 'GET',
                    params:this.paramsImprime,
                    responseType: 'arraybuffer',

                    
                })

                this.loadingDescarga = false

                // Obtiene el nombre del archivo origen
                let qs1 = dt.request.responseURL.split('?')
                let vars = qs1[1].split('&');
                let getVars = {};
                let tmp = '';
                vars.forEach(function(v){
                    tmp = v.split('=');
                    if(tmp.length == 2)
                    getVars[tmp[0]] = tmp[1];
                });
                
                // Crea el archivo y lo manda
                var headers = dt.headers;
                var blob = new Blob([dt.data],{type:headers['content-type']});
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = getVars._name+'.txt';
                link.click();
                
                
            }catch(err){

                this.loadingDescarga = false
                this.$store.dispatch('security/revisaError',err)
            }
            
            
        },
    },

    computed:{
        estaFecha(){
            return (this.moment(new Date()).format('YYYY-MM-DD'))
        },
        
        fechaDe(){
            return (this.getFecha(this.fechaArr[0]))
        },
        fechaA(){
            return (this.getFecha(this.fechaArr[1]))
        },

        
        
    },
    watch:{
        fechas(){
            let far=[]
            this.fechaArr = []
            if(this.fechas.length > 0){
                this.fechas.forEach(fecha => {
                    far.push(new Date(fecha+'T00:00:00'))
                })

                this.fechaArr.push(new Date(Math.min.apply(null,far)))
                this.fechaArr.push(new Date(Math.max.apply(null,far)))
            }

            
        },
        queryMedico(){
            if (!this.loadingMedicos && this.queryMedico){
                //this.paramsConsultas.query = this.queryMedico
                this.queryMedico.length>=3?this.cargaMedico():''
            }
        }
    },
}
</script>

<style>

</style>